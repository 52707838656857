<template>
  <div>
    <CDropdown inNav class="c-header-nav-items" placement="bottom-end" add-menu-classes="pt-0">
      <template #toggler>
        <CHeaderNavLink>
          <div class="c-avatar">
            <img src="/img/avatars/boy.png" class="c-avatar-img" />
          </div>
        </CHeaderNavLink>
      </template>
      <CDropdownItem @click="topup">
        <CIcon name="cil-cursor" /> Topup Token (demo)
      </CDropdownItem>
      <CDropdownItem @click="logout">
        <CIcon name="cil-lock-locked" /> Logout
      </CDropdownItem>
    </CDropdown>
    <CModal title="Confirm credit topup" color="info" :show.sync="topupModal" @update:show="closeModal">
      This is how you can topup your credit in the demo environment.
      No integration with payment gateway is done. Click OK to topup your credit with IDR 5000.
    </CModal>
  </div>
</template>

<script>
import { AUTH_LOGOUT } from "../store/actions/auth";
import axios from "axios";

export default {
  name: "TheHeaderDropdownAccnt",
  data() {
    return {
      alertColor: "success",
      alertShow: false,
      alertMessage: "",
      topupModal: false,
    };
  },
  methods: {
    logout: function () {
      this.$store.dispatch(AUTH_LOGOUT).then(() => {
        this.$router.push("/login");
      });
    },
    topup: function () {
      this.topupModal = true;
    },
    closeModal(status, evt, accept) {
      if (accept) {
        axios
          .post(config.VUE_APP_ENV_TUTOR_URL + "/me/topup", {
            amount: 5000,
          })
          .then((resp) => {

          })
          .catch((err) => {
            if (err.message.includes("401")) {
              this.$store.dispatch(AUTH_LOGOUT).then(() => {
                this.$router.push("/login");
              });
            } else {
              this.showAlert("warning", "Failed to delete proxy : " + err);
            }
          });
      }
    },
  },
};
</script>

<style scoped>
.c-icon {
  margin-right: 0.3rem;
}
</style>
