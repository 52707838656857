<template>
  <CFooter :fixed="false">
    <div>
      <a>Tutor</a>
      <span class="ml-1">&copy; {{ new Date().getFullYear() }}
        <a href="https://aegis.co.id">AegisLabs</a></span>
    </div>
    <div class="mfs-auto">
      <a>While Tutor aims to provide accurate information, please note it can occasionally produce incorrect
        or outdated responses.</a>
    </div>
    <div class="mfs-auto">
      <span class="mr-1" target="_blank">Powered by</span>
      <a href="https://coreui.io/vue">CoreUI for Vue</a>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: "TheFooter",
};
</script>
